import VueI18n from 'vue-i18n'
import Vue from 'vue'

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/config/i18n'
import de from '@/translations/de.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { de }
})
