import studyService from '@/services/studyService'

const initialArchiveState = () => ({
  results: [],
  pagination: {
    count: 0,
    pages: 0,
    page: 0,
    has_next: undefined,
    has_previous: undefined,
  },
})

export const namespaced = true

export const state = () => ({
  isLoadingEntries: false,
  pageSize: 25,
  studies: [
    {
      id: '2131-324-13-123-1-3',
      key: 'snvcs',
      name: 'Durchimpfungsstudie',
      slug: 'snvcs',
      state: 'running',
      entries: initialArchiveState(),
      selectedEntries: [],
      cantonsWithEntries: [],
      filteredId: '',
      filteredCanton: '',
      sortBy: '-timestamp',
    },
    {
      id: '2131-324-13-123-1-2',
      key: 'hpvcs',
      name: 'HPV Studie',
      slug: 'hpvcs',
      backend: 'hpvcs',
      state: 'running',
      entries: initialArchiveState(),
      selectedEntries: [],
      cantonsWithEntries: [],
      filteredId: '',
      filteredCanton: '',
      sortBy: '-timestamp',
    },
    {
      id: '12413-123-135-1-26-8',
      key: 'pvcs',
      name: 'Pneumokokken Impfstudie',
      slug: 'pvcs',
      state: 'closed',
      entries: initialArchiveState(),
      selectedEntries: [],
      cantonsWithEntries: [],
      filteredId: '',
      filteredCanton: '',
      sortBy: '-timestamp',
    },
  ],
})

export const getters = {
  getStudyBySlug: (state) => (slug) =>
    state.studies.find((study) => study.slug === slug),
  entryIsSelected:
    (state) =>
    ({ slug, id }) => {
      const study = state.studies.find((s) => s.slug === slug)

      return study.selectedEntries.includes(id)
    },
}

export const mutations = {
  SET_LOADING(state, loading) {
    state.isLoadingEntries = loading
  },
  addStudyEntries(state, { key, data }) {
    const study = state.studies.find((s) => s.key === key)

    const formatted = data.map((entry) => {
      entry.formattedDate = new Date(entry.birthdate)
      entry.formattedTimestamp = new Date(entry.timestamp)

      return entry
    })

    study.entries.results = [...formatted]
  },
  UPDATE_PAGINATION(state, { slug, pagination }) {
    const study = state.studies.find((s) => s.slug === slug)

    study.entries.pagination = pagination
  },
  REMOVE_ENTRY(state, { slug, id }) {
    const study = state.studies.find((s) => s.slug === slug)

    const index = study.entries.results.findIndex((entry) => entry.id === id)

    study.entries.results.splice(index, 1)
  },
  DESELECT_ENTRY(state, { slug, payload }) {
    const study = state.studies.find((s) => s.slug === slug)

    if (payload === 'EXTERMINATE') {
      study.selectedEntries = []
    } else {
      const index = study.selectedEntries.findIndex(
        (entry) => entry === payload
      )

      study.selectedEntries.splice(index, 1)
    }
  },
  SELECT_ENTRY(state, { slug, payload }) {
    const study = state.studies.find((s) => s.slug === slug)

    if (Array.isArray(payload)) {
      study.selectedEntries = payload
    } else {
      study.selectedEntries.push(payload)
    }
  },
  UPDATE_SORTING(state, { slug, order }) {
    const study = state.studies.find((s) => s.slug === slug)

    study.sortBy = order
  },
  UPDATE_SEARCH_ID(state, { slug, id }) {
    const study = state.studies.find((s) => s.slug === slug)

    study.filteredId = id
  },
  UPDATE_FILTER_CANTON(state, { slug, canton }) {
    const study = state.studies.find((s) => s.slug === slug)

    study.filteredCanton = canton
  },
  UPDATE_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  RESET_ARCHIVE(state, slug) {
    const study = state.studies.find((s) => s.slug === slug)

    study.entries = initialArchiveState()
  },
  MARK_AS_DOWNLOADED(state, { slug, ids }) {
    const study = state.studies.find((s) => s.slug === slug)

    ids.forEach((id) => {
      const entry = study.entries.results.find((e) => e.id === id)

      if (entry) {
        entry.download_timestamp = Date.now()
      }
    })
  },
  MARK_ALL_AS_DOWNLOADED(state, { slug }) {
    const study = state.studies.find((s) => s.slug === slug)

    study.entries.results.forEach((entry) => {
      entry.download_timestamp = Date.now()
    })
  },
}

export const actions = {
  async getEntries({ getters, commit, rootState, state }, config = {}) {
    commit('SET_LOADING', true)

    try {
      const { slug } = rootState.route.params
      const study = getters.getStudyBySlug(slug)

      // clear selection as selected items will not be visible anymore
      commit('DESELECT_ENTRY', { slug, payload: 'EXTERMINATE' })

      const { results, pagination } = await studyService.getEntries(slug, {
        sortBy: config.ordering || study.sortBy,
        canton: config.canton || study.filteredCanton,
        filteredId: config.id || study.filteredId,
        pageSize: config.page_size || state.pageSize,
        page: config.page || study.entries.pagination.page + 1,
      })

      commit('addStudyEntries', { key: slug, data: results })
      commit('UPDATE_PAGINATION', { slug, pagination })
    } catch (e) {
      console.error(e)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async reorder({ commit, state }, { slug, order }) {
    commit('UPDATE_SORTING', { slug, order })
    commit('SET_LOADING', true)

    commit('DESELECT_ENTRY', { slug, payload: 'EXTERMINATE' })

    const { results, pagination } = await studyService.getEntries(slug, {
      sortBy: order,
      pageSize: state.pageSize,
      page: 1,
    })

    commit('addStudyEntries', { key: slug, data: results })
    commit('UPDATE_PAGINATION', { slug, pagination })
    commit('SET_LOADING', false)
  },
  changePageSize({ commit, dispatch, rootState }, size) {
    const { slug } = rootState.route.params

    commit('SET_LOADING', false)
    commit('UPDATE_PAGE_SIZE', size)
    commit('RESET_ARCHIVE', slug)

    return dispatch('getEntries')
  },
  async deleteEntry({ commit, rootState }, { id }) {
    const { slug } = rootState.route.params

    const { status } = await studyService.deleteEntry(id, slug)

    if (status === 204) {
      commit('REMOVE_ENTRY', { slug, id })
    }

    return status
  },
  async deleteSelectedEntries({ getters, commit, dispatch, rootState }) {
    const { slug } = rootState.route.params
    const { selectedEntries, entries } = getters.getStudyBySlug(slug)

    const status = await studyService.deleteEntries({
      ids: selectedEntries,
      slug,
    })

    if (status === 204) {
      commit('DESELECT_ENTRY', { slug, payload: 'EXTERMINATE' })

      return dispatch('getEntries', { page: entries.pagination.page })
    } else {
      throw status
    }
  },
  async downloadSelectedEntries({ getters, commit, rootState }) {
    const { slug } = rootState.route.params
    const { selectedEntries } = getters.getStudyBySlug(slug)

    const { status, data: file } = await studyService.downloadEntries({
      ids: selectedEntries,
      slug,
    })

    if (status === 200) {
      commit('MARK_AS_DOWNLOADED', { slug, ids: selectedEntries })

      return {
        file,
        fileName: `${slug.toUpperCase()}_Download-${selectedEntries
          .sort()
          .join('_')}.zip`,
      }
    } else {
      throw new Error(status)
    }
  },
  async downloadNewEntries({ commit, rootState }) {
    const { slug } = rootState.route.params

    const { status, data: file } = await studyService.downloadNewEntries({
      slug,
    })

    if (status === 200) {
      commit('MARK_ALL_AS_DOWNLOADED', { slug })
      return {
        file,
        fileName: `${slug.toUpperCase()}_Download-new.zip`,
      }
    } else {
      throw new Error(status)
    }
  },
}
