import jwtDecode from 'jwt-decode'

import store from '@/store/index'

const MILLISEC = 1000
const SEC = 1
const MIN = 60 * SEC
const HOUR = 60 * MIN

const ACCESS_LIFETIME = 30 * MIN
const REFRESH_LIFETIME = 12 * HOUR
// const LOGOUT_TRESHOLD = 0.5 * MIN

function getTokenLifetime() {
  let { settings } = store.state.account

  return settings
    ? settings.api.lifetimes
    : { access: ACCESS_LIFETIME, refresh: REFRESH_LIFETIME }
}

export function checkTokenValidity() {
  const NOW_SEC = Date.now() / MILLISEC
  if (
    store.state.account.user.token.access === null ||
    store.state.account.user.token.refresh === null
  ) {
    return {
      accessible: false,
      refreshable: false,
    }
  }
  let { access, refresh } = store.state.account.user.token
  let decoded = { access: jwtDecode(access), refresh: jwtDecode(refresh) }
  let lifetimes = getTokenLifetime()

  const ACCESS_THRESHOLD = 0.05 * parseInt(lifetimes.access)
  const REFRESH_THRESHOLD = 0.02 * parseInt(lifetimes.refresh)

  return {
    access: decoded.access.exp - NOW_SEC,
    accessible: decoded.access.exp - NOW_SEC > Math.max(ACCESS_THRESHOLD, 10),
    refresh: decoded.refresh.exp - NOW_SEC,
    refreshable:
      decoded.refresh.exp - NOW_SEC > Math.max(REFRESH_THRESHOLD, 30),
  }
}
