import api from './api'
import formatStudyId from '../util/formatStudyId'
import { value_mapping } from '@/config/hpvcs_value_mapping'

import store from '@/store'

const makeIdQuery = (ids) => `id=${ids.join('&id=')}`

const MARK_FILE_UPLOAD_START = 'upload-start'
const MEASURE_NAME_FILE_UPLOAD = 'File upload'
const MARK_UPLOAD_TOTAL_START = 'upload-total-time'
const MEASURE_NAME_UPLOAD_TOTAL = 'Total upload time'

export default {
  /**
   * Send a `POST` to create a new study entry
   *
   * @param {String} study
   * @param {Object} formData
   * @returns {Object|Error} Status and created object or error
   */
  async create(study, formData) {
    formData['subject_id'] = formatStudyId(formData['subject_id'])
    performance?.mark(MARK_UPLOAD_TOTAL_START)

    if (formData.vacSheet == '0') formData.files = []
    if (formData.files.length > 0) formData.vacSheet = 1
    const createFrom = {
      birthdate: formData.birthdate,
      canton: formData.canton,
      subject_id: formData.subject_id,
      vac_sheet: formData.vacSheet,
      reason: {
        reason: formData.reason,
        other_reason: formData.otherReason,
      },
    }
    return this.prepareFileUpload(
      createFrom,
      formData,
      study,
      formData.files,
      false
    )
  },

  clean_when_not_online(formData) {
    if (formData.online == '1') {
      const res = {
        subject_id: formData.subject_id,
        birth_year: formData.birth_year,
        vacSheet: formData.vacSheet,
        files: formData.files,
        filesSurvey: formData.filesSurvey,
      }
      formData = res
    }
    return formData
  },

  clean_conditional_data(formData) {
    if (formData.vaccinated_for_HPV === value_mapping.yes) {
      formData.what_prevented_you = undefined
      formData.future_vaccination = undefined
    }
    if (
      formData.vaccinated_for_HPV === value_mapping.no ||
      formData.vaccinated_for_HPV === value_mapping.unsure
    ) {
      formData.what_prompted_you = undefined
      formData.where_vaccinated = undefined
    }
    return formData
  },

  clean_files(formData) {
    if (formData.vacSheet == value_mapping.vac_sheet.no_vaccination_card)
      formData.files = []
    if (formData.online == '0') formData.filesSurvey = []
    return formData
  },

  async createHpvcsUpload(study, formData) {
    performance?.mark(MARK_UPLOAD_TOTAL_START)
    formData = this.clean_files(formData)
    formData = this.clean_when_not_online(formData)
    //if (formData.files.length > 0) formData.vacSheet = 1
    formData = this.clean_conditional_data(formData)
    const createFrom = {
      subject_id: formData.subject_id,
      gender: formData.gender,
      birth_year: formData.birthyear,
      civil_status: formData.civil_status,
      knowledge_about_hpv: formData.knowledge_about_hpv,
      vaccination_recommended: formData.vaccination_recommended,
      risk_developing_HPV: formData.risk_developing_HPV,
      how_important_feel_of_HPV: formData.how_important_feel_of_HPV,
      how_important_feel_for_men: formData.how_important_feel_for_men,
      how_important_feel_for_women: formData.how_important_feel_for_women,
      associated_with_HPV: formData.associated_with_HPV,
      visit_doctor: formData.visit_doctor,
      type_of_visit: formData.type_of_visit,
      number_of_sexual_partners: formData.number_of_sexual_partners,
      vaccinated_for_HPV: formData.vaccinated_for_HPV,
      what_prompted_you: formData.what_prompted_you,
      where_vaccinated: formData.where_vaccinated,
      what_prevented_you: formData.what_prevented_you,
      future_vaccination: formData.future_vaccination,
      vac_sheet: formData.vacSheet,
    }
    const files = [...formData.files, ...formData.filesSurvey]
    return this.prepareFileUpload(createFrom, formData, study, files, true)
  },

  async prepareFileUpload(createFrom, formData, study, files, flag) {
    let token = ''
    let id = ''
    let currentFile = 0
    try {
      const { status, data } = await api.post(`/study/${study}/`, createFrom)

      window.plausible?.('Base data upload successful', { props: { study } })

      token = data.temp_token
      id = data.id

      const fileUploads = []
      store.commit('upload/UPDATED_MAIN', true)
      for (let file of files) {
        currentFile++
        performance?.mark(MARK_FILE_UPLOAD_START)
        const headers = flag
          ? this.createHpvHeaders(
              file,
              id,
              token,
              currentFile > formData.files.length ? false : true
            )
          : this.createHeaders(file, id, token)
        const { data: fileData } = await this.uploadFile(file, headers, study)

        fileUploads.push(fileData)
        store.commit('upload/UPDATE_COMPLETE_FILES', fileUploads.length)

        performance?.measure(MEASURE_NAME_UPLOAD_TOTAL, MARK_UPLOAD_TOTAL_START)

        const measurements = performance?.getEntriesByName(
          MEASURE_NAME_UPLOAD_TOTAL,
          'measure'
        )

        window.plausible?.('Image upload successful', {
          props: {
            study,
            totalFiles: formData.files.length,
            currentFile,
            duration:
              measurements && Math.round(measurements[0].duration / 1000),
          },
        })

        performance?.clearMarks(MARK_FILE_UPLOAD_START)
        performance?.clearMeasures(MEASURE_NAME_FILE_UPLOAD)
      }

      return { status, data, fileUploads }
    } catch (err) {
      await api.delete(`/study/${study}/${id}/clean-up/`, {
        headers: {
          'X-Upload-Token': token,
        },
      })

      const measurements = performance?.getEntriesByName(
        MEASURE_NAME_UPLOAD_TOTAL,
        'measure'
      )

      const props = {
        errorMessage: err?.message,
        statusCode: err?.response.status,
        study,
        baseDataComplete: store.state.upload.mainComplete,
        totalFiles: formData.files.length,
        completedFiles: store.state.upload.filesComplete,
        duration: measurements && Math.round(measurements[0].duration / 1000),
      }

      window.plausible?.('Upload failed', {
        props,
      })

      store.commit('upload/RESET')
      performance?.clearMarks(MARK_UPLOAD_TOTAL_START)
      performance?.clearMeasures(MEASURE_NAME_FILE_UPLOAD)

      throw err
    }
  },

  createHeaders(file, id, token) {
    return {
      'X-Upload-Id': id,
      'X-Upload-Token': token,
      'Content-Disposition': `attachment; filename=${file.name}`,
    }
  },

  createHpvHeaders(file, id, token, flag) {
    return { ...this.createHeaders(file, id, token), 'X-Upload-VAC': flag }
  },

  async uploadFile(file, headers, study) {
    return api.post(`/study/${study}/files/`, file, {
      headers: headers,
    })
  },

  lockEntry(slug, id, token) {
    return api.post(
      `/study/${slug}/${id}/lock/`,
      {},
      {
        headers: {
          'X-Upload-Token': token,
        },
      }
    )
  },

  async getEntries(slug, config) {
    let path = `/study/${slug}/uploads/?ordering=${config.sortBy}&page_size=${config.pageSize}&page=${config.page}`

    if (config.canton) {
      path = path.concat('&canton=', config.canton)
    }

    if (config.filteredId) {
      path = path.concat('&id=', config.filteredId)
    }
    const { data } = await api.get(path)

    return data
  },

  async deleteEntry(id, slug) {
    const res = await api.delete(`/study/${slug}/uploads/${id}/`)

    return res
  },

  async downloadEntry({ id, slug }) {
    const { data, status } = await api.get(
      `/study/${slug}/uploads/${id}/download/`,
      {
        responseType: 'blob',
      }
    )

    if (status === 200) {
      return data
    }
  },

  async deleteEntries({ ids, slug }) {
    const { status } = await api.delete(
      `/study/${slug}/uploads/delete/?${makeIdQuery(ids)}`
    )

    return status
  },

  async downloadEntries({ ids, slug }) {
    const { data, status } = await api.get(
      `/study/${slug}/uploads/bulkdownload/?${makeIdQuery(ids)}`,
      {
        responseType: 'blob',
      }
    )

    return { data, status }
  },
  async downloadNewEntries({ slug }) {
    const { data, status } = await api.get(
      `/study/${slug}/uploads/notdownloadedyet/`,
      {
        responseType: 'blob',
      }
    )

    return { data, status }
  },

  getInfos(study) {
    return api.get(`/study/${study}/info/`)
  },
}
