export const value_mapping = {
  yes: 1,
  no: 0,
  unsure: 99,
  low: 13,
  medium: 14,
  high: 15,
  other: 99,
  gender: {
    male: 1,
    female: 0,
  },
  civil_status: {
    single: 2,
    widowed: 3,
    married: 4,
    separated: 5,
  },
  knowledge_about_hpv: {
    heard_of_HPV: 2,
    not_heard_of_HPV: 3,
    problems_related_to_HPV: 4,
    know_someone_with_problems_related_to_HPV: 5,
    do_not_know_anyone: 6,
  },
  associated_with_HPV: {
    cold: 2,
    genital_warts: 3,
    head_cancer: 4,
    cervical_cancer: 5,
    anal_cancer: 6,
    kidney_problems: 7,
    bladder_infection: 8,
    paralysis: 9,
  },
  visit_doctor: {
    zero_two: 2,
    three_five: 3,
    more_than_five: 4,
  },
  type_of_visit: {
    primary: 2,
    ob_gyn: 3,
    pharmacist: 4,
    complementary: 5,
    other_specialist: 6,
  },
  what_prompted_you: {
    doctor_recommendation: 2,
    asked: 3,
    know_someone_with_problems: 4,
    information_from_internet: 5,
    information_from_school: 6,
    at_risk: 7,
    protect_partner: 8,
  },
  where_vaccinated: {
    school: 2,
    ob_gyn: 3,
    pediatrician: 4,
    primary_care: 5,
    pharmacy: 6,
  },
  what_prevented_you: {
    not_enough_knowledge: 2,
    no_suggestion: 3,
    declined: 4,
    skeptical: 5,
    fear_of_side_effects: 6,
    too_expensive: 7,
    no_time: 8,
    not_at_risk: 9,
    not_age_group: 10,
  },
  vac_sheet: {
    vac_sheet_available: 1,
    no_vaccination_card: 0,
  },
  number_of_sexual_partners: {
    zero_one: 2,
    two_five: 3,
    six_plus: 4,
  },
}
