import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import loadView from '@/util/loadView'

import { I18nHelper } from '@/plugins/i18nHelper'

const PublicLayout = () => import('@/layouts/public')
const BackendLayout = () => import('@/layouts/backend')

import publicRoutes from './routes/public'
import backendRoutes from './routes/backend'

import loggedIn from './guards/loggedIn'

Vue.use(Router)
Vue.use(Meta, {
  keyName: 'head'
})

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/backend',
      component: BackendLayout,
      beforeEnter: loggedIn,
      children: [...backendRoutes]
    },
    {
      path: '/login',
      name: 'login',
      component: loadView('backend/Login')
    },
    {
      path: '/:lang',
      component: PublicLayout,
      beforeEnter: I18nHelper.routeMiddleware,
      children: [...publicRoutes]
    },
    {
      // Redirect user to supported lang version.
      path: '*',
      redirect() {
        return I18nHelper.getUserSupportedLang()
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  }
})
