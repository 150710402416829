<template>
  <header class="page-header">
    <p v-if="content.aside" class="aside">{{ content.aside }}</p>
    <h1 class="main-headline">{{ content.title }}</h1>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
