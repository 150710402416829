import { snvcs, pvcs } from '@/config/regex/ids'

/**
 * Unify user input
 *
 * @export
 * @param {String} unparsedId
 * @returns {String}
 */
export default function formatStudyId(unparsedId) {
  if (snvcs.test(unparsedId)) {
    return unparsedId
  }

  if (pvcs.test(unparsedId)) {
    // replace spaces and dashes that are no normal dash
    return unparsedId.replace(/ –—/g, '-')
  }

  return unparsedId
}
