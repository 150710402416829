import api from '@/services/api'

export default {
  async getToken(credentials) {
    return api.post('/auth/login/', credentials)
  },

  refreshToken(refresh) {
    return api.post('/auth/refresh-token/', { refresh })
  },

  verifyToken(token) {
    return api.post('/auth/verify-token/', { token })
  },
}
