import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as account from './modules/account'
import * as i18n from './modules/i18n'
import * as notification from './modules/notification'
import * as studies from './modules/studies'
import * as upload from './modules/upload'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    i18n,
    notification,
    studies,
    upload
  },
  plugins: [
    createPersistedState({
      paths: ['account']
    })
  ]
})
