import api from '@/services/api'
import router from '@/router'
import auth from '@/services/auth'

const initialUserState = () => ({
  user: null,
  token: { access: null, refresh: null },
  isLoggedIn: false,
  preferedTableLayout: 'dense',
})

export const namespaced = true

export const state = () => ({
  user: initialUserState(),
})

export const mutations = {
  LOGOUT: (state) => {
    state.user = initialUserState()
  },
  setToken: (state, payload) => {
    state.user.token.access = payload.access
    state.user.token.refresh = payload.refresh
    state.user.isLoggedIn = true
  },
  setaAccessToken: (state, payload) => {
    state.user.token.access = payload.access
    state.user.isLoggedIn = true
  },
  setInfo: (state, payload) => {
    state.user.user = payload
  },
  TABLE_LAYOUT: (state, layout) => {
    state.user.preferedTableLayout = layout
  },
}

export const getters = {
  isLoggedIn: ({ user }) => user && user.isLoggedIn,
}

export const actions = {
  async login({ commit, dispatch }, data) {
    const response = await auth.getToken(data)
    if (response.status === 200) {
      commit('setToken', response.data)
      dispatch('authenticateApiService')
      return true
    }

    throw new Error('Network failure')
  },

  async refreshToken({ commit, dispatch, state }) {
    try {
      const { data } = await auth.refreshToken(state.user.token.refresh)
      commit('setaAccessToken', data)
      dispatch('authenticateApiService')

      return data
    } catch (e) {
      dispatch('forceLogout')
    }
  },

  logout({ commit }) {
    commit('LOGOUT')

    delete api.defaults.headers['Authorization']
  },

  forceLogout({ dispatch, commit }, message) {
    commit(
      'notification/SET_NOTIFICATION',
      {
        level: 'error',
        message,
        placement: 'top',
      },
      { root: true }
    )

    commit('setToken', { refresh: null, access: null })
    dispatch('logout')
    router.push({ name: 'login' })
  },

  authenticateApiService({ state }) {
    api.defaults.headers['Authorization'] = `JWT ${state.user.token.access}`
  },
}
