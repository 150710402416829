import Vue from 'vue'
import VueMask from 'v-mask'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loade
import { sync } from 'vuex-router-sync'
import Vuelidate from 'vuelidate'
import AsyncComputed from 'vue-async-computed'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import dayjs from 'dayjs'

import store from '@/store'
import router from '@/router'
import { i18n } from '@/plugins/i18n'
import { I18nHelper } from '@/plugins/i18nHelper'

import App from '@/App.vue'
import BaseIcon from '@/components/base/icons/BaseIcon.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import PageHeader from '@/components/ui/PageHeader.vue'

import '@/assets/styles/main.scss'

Vue.config.productionTip = false

Vue.prototype.$i18nRoute = I18nHelper.i18nRoute.bind(I18nHelper)
Vue.prototype.$dayjs = dayjs

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(AsyncComputed)
Vue.use(Vuetify)

const baseComponents = [PageHeader, BaseIcon, BaseLoader]

// Register all base components
baseComponents.forEach((component) => {
  Vue.component(component.name, component)
})

sync(store, router)
function osTheme() {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark'
  }
  return 'light'
}

const vue = new Vue({
  vuetify: new Vuetify({
    theme: {
      light: osTheme() == 'light',
      dark: osTheme() == 'dark',
    },
  }),
  router,
  store,
  i18n,
  render: (h) => h(App),
})

const script = document.createElement('script')
script.src = 'https://plausible.io/js/plausible.js'
script.dataset.domain = location.hostname
script.setAttribute('defer', '')

document.head.appendChild(script)

const globalPlausible = document.createElement('script')
globalPlausible.innerText = `window.plausible =
        window.plausible ||
        function() {
          ;(window.plausible.q = window.plausible.q || []).push(arguments)
        }`

document.head.appendChild(globalPlausible)

vue.$mount('#app')
