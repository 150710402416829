export const state = () => ({
  currentLanguage: null
})

export const namespaced = true

export const mutations = {
  CHANGE_LANGUAGE: (state, lang) => {
    state.currentLanguage = lang
  }
}

export const actions = {
  changeLanguage({ commit }, value) {
    commit('CHANGE_LANGUAGE', value)
  }
}
