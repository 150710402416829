const initialState = {
  mainComplete: false,
  completedFiles: 0,
  uploadedId: null
}

export const namespaced = true

export const state = () => ({ ...initialState })

export const mutations = {
  UPDATED_MAIN: (state, payload) => {
    state.mainComplete = payload
  },

  UPDATE_COMPLETE_FILES: (state, count) => {
    state.completedFiles = count
  },

  SET_ID: (state, id) => {
    state.uploadedId = id
  },

  RESET: state => {
    state.mainComplete = initialState.mainComplete
    state.completedFiles = initialState.completedFiles
  }
}
