import loadView from '@/util/loadView'

export default [
  {
    path: '/',
    name: 'studies',
    component: loadView('StudyOverview'),
  },
  {
    path: ':slug/helpsnv',
    name: 'helpsnv',
    component: loadView('HelpPageSNV'),
  },
  {
    path: ':slug/helphpv',
    name: 'helphpv',
    component: loadView('HelpPageHPV'),
  },
  {
    path: 'success',
    name: 'success',
    component: loadView('Success'),
  },
  {
    path: 'study/:slug',
    name: 'studyDetail',
    component: loadView('StudyDetail'),
    children: [],
  },
  {
    path: 'study/:slug/description',
    name: 'studyDescription',
    component: loadView('StudyDescription'),
  },
  {
    path: '*',
    name: '404',
    component: loadView('404'),
  },
]
