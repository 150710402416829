import store from '@/store'

export default async (to, from, next) => {
  if (store.getters['account/isLoggedIn']) {
    // we need to do before we enter the backend,
    // because the API service is not persisted between sessions
    store.dispatch('account/authenticateApiService')

    return next()
  }

  next('/login')
}
