import loadView from '@/util/loadView'

export default [
  {
    path: '/',
    name: 'backend',
    component: loadView('backend/Overview'),
  },
  {
    path: ':slug',
    name: 'backendStudyDetail',
    component: loadView('backend/StudyDetail'),
  },
]
