export const DEFAULT_LANGUAGE = 'de'
export const FALLBACK_LANGUAGE = 'de'
export const SUPPORTED_LANGUAGES = ['de', 'fr', 'en', 'it']
export const LANGUAGE_DATA = [
  {
    code: 'de',
    name: 'Deutsch'
  },
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'fr',
    name: 'Français'
  },
  {
    code: 'it',
    name: 'Italiano'
  }
]
